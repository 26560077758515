import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaCalendarAlt, FaEnvelope } from 'react-icons/fa';
import { useLanguage } from '../../Context';
import { translations } from '../../Translation';
import './ContactPage.css';


function ContactPage() {
    const [activeTab, setActiveTab] = useState('calendly');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phonenumber: '',
        country: '',
        message: ''
    });
    const { language } = useLanguage();
    const text = translations[language];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault(); // Prevent the form from redirecting the page.

        emailjs.send('service_2x7drsq', 'template_mk7xc1m', formData, '9BeYQ5_KWo-SWHtiI')
            .then((result) => {
                setFormData({
                    name: '',
                    email: '',
                    phonenumber: '',
                    country: '',
                    message: ''
                });
                alert('Email successfully sent!', result.text);
                // Reset form or give feedback to the user.
            }, (error) => {
                console.log('Failed to send email.', error.text);
            });
    };

    return (
        <div>
            <div className='banner-image'></div>
            <div className="contact-container">
                <div className="active-content">
                    <div className="contact-tabs">
                        <button className={`tab ${activeTab === 'calendly' ? 'active' : ''}`} onClick={() => setActiveTab('calendly')}>
                            <FaCalendarAlt style={{paddingRight: '5px'}}/> { text.scheduleMeeting }
                        </button>
                        <button className={`tab ${activeTab === 'form' ? 'active' : ''}`} onClick={() => setActiveTab('form')}>
                            <FaEnvelope style={{paddingRight: '5px'}}/> { text.contactForm }
                        </button>
                    </div>

                    {activeTab === 'calendly' && (
                        <div className="calendly-embed">
                            <iframe 
                                src="https://calendly.com/internoneusa/30min?month=2024-04"
                                width="100%" 
                                height="600" 
                                frameBorder="0"
                                title="Schedule a 30-minute meeting with InternOne">
                                    
                            </iframe>
                        </div>
                    )}

                    {activeTab === 'form' && (
                        <div className="contact-form">
                            <form onSubmit={sendEmail}>
                                <div className="form-group">
                                    <input type="text" required name="name" placeholder="" value={formData.name} onChange={handleChange}/>
                                    <label htmlFor="name">{ text.nameLabel }</label>
                                </div>
                                <div className="form-group">
                                    <input type="email" required name="email" placeholder="" value={formData.email} onChange={handleChange}/>
                                    <label htmlFor="email">{ text.emailLabel }</label>
                                </div>
                                <div className="form-group">
                                    <input type="text" name="phonenumber" placeholder="" value={formData.phonenumber} onChange={handleChange}/>
                                    <label htmlFor="phonenumber">{ text.phoneNumberLabel }</label>
                                </div>
                                <div className="form-group">
                                    <input type="text" required name="country" placeholder="" value={formData.country} onChange={handleChange}/>
                                    <label htmlFor="country">{ text.countryLabel }</label>
                                </div>
                                <div className="form-group">
                                    <textarea required name="message" placeholder="" value={formData.message} onChange={handleChange}></textarea>
                                    <label htmlFor="message">{ text.messageLabel }</label>
                                </div>
                                <button type="submit">{ text.sendButton }</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
