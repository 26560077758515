import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { useLanguage } from '../../Context';
import { translations } from '../../Translation';
import './FaqPage.css';

const FaqPage = () => {
    const [activeIndex, setActiveIndex] = useState(null); // Keeps track of the active FAQ item
    const { language } = useLanguage();
    const text = translations[language];

    
    const faqData = [
        { question: text.mission, answer: text.missionText },
        { question: text.services, answer: text.servicesText },
        { question: text.advantages, answer: text.advantagesText },
        { question: text.charge, answer: text.chargeText }
    ];

    const toggleFAQ = index => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div>
            <div className='banner-image'></div>
            <div className="faq-section">
                <h2>{language === 'en' ? 'FAQ' : '常见问题'}</h2>
                {faqData.map((faq, index) => (
                    <div className={`faq-item ${index === activeIndex ? 'active' : ''}`} key={index} onClick={() => toggleFAQ(index)}>
                    <div className="faq-question">
                        {faq.question}
                        {index === activeIndex ? <MdExpandLess /> : <MdExpandMore />}
                    </div>
                    <div className="faq-answer">
                        <p>{faq.answer}</p>
                    </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FaqPage;
