import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import FaqPage from './pages/FaqPage';
import AchievementsPage from './pages/AchievementPage';
import PlansPage from './pages/PlansPage';
import Footer from './components/Footer';
import { createGlobalStyle } from 'styled-components';
import { LanguageProvider } from './Context';
import { initGA, logPageView } from './ga4';

const DynamicGlobalStyle = createGlobalStyle`
  body {
    margin-top: ${props => props.path === '/' ? '0px' : '70px'};
    font-family: 'Arial', sans-serif;
    background: #f0f0f0;  // Adds a light grey background to enhance legibility
  }
`;

function GlobalStyler() {
  const location = useLocation(); 
  return <DynamicGlobalStyle path={location.pathname} />;
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <>
      <GlobalStyler />
      <div className="site-container">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/achievements" element={<AchievementsPage />} />
          <Route path="/plans" element={<PlansPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FaqPage />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <LanguageProvider>
      <Router>
        <AppContent />
      </Router>
    </LanguageProvider>
  );
}

export default App;
