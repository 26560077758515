export const translations = {
    en: {
      achievements: "Achievements",
      plans: "Plans",
      contact: "Contact Us",
      faq: "FAQ",
      mission: "What is our mission?",
      missionText: "Our mission is to help U.S. students secure their first internships in key fields such as finance, business, marketing, consulting, and computer science.",
      services: "Why our services?",
      servicesText: "We believe that personal development is the most effective path to employment. Our services are designed to enhance your competitiveness in the job market through comprehensive career planning, resume enhancement, strategic networking, and interview preparation.",
      advantages: "Why we have the advantage.",
      advantagesText: "We are crafted by Generation Z for Generation Z, and is dedicated to providing efficient, reliable, and cost-effective career counseling services. Our network includes tutors from top U.S. universities and professionals from leading companies, ensuring you have direct communication bridges with industry insiders.",
      charge: "What is the Pricing?",
      chargeText: "Our goal is to democratize understanding of the U.S. recruitment process and empower more students with the skills to navigate the job market. We offer competitive pricing to open doors for students eager to enter their preferred job sectors.",
      scheduleMeeting: "Schedule Meeting",
      contactForm: "Contact Form",
      nameLabel: "Name:*",
      emailLabel: "Email:*",
      phoneNumberLabel: "WeChat / Phonenumber:",
      countryLabel: "Country:*",
      messageLabel: "Message:*",
      sendButton: "Send",
      aboutUsText: "About Us",
      aboutUs: "Crafted by Gen Z for Gen Z. Intern-One offers professional, meticulous, comprehensive, and customized job search guidance for international students across North America. \nOur mission is to assist every mentee with a dream in securing their first internship. We strongly believe that success is the result of persistent hard work. By developing students' professional skills, networking capabilities, and interview techniques, we're dedicated to helping them enter their ideal companies. Our team of mentors, drawn from the Top 30 universities in the United States and from prestigious companies, will be deeply passionate about empowering each student to succeed.",
      ourGoalTitle: "Our Goal",
      ourGoalText: "To revolutionize the career consulting industry for international students through personalized tutoring, ensuring each of our mentees finds the job that best suits them.",
      ourVisionTitle: "Our Vision",
      ourVisionText: "To break down the 'bamboo ceiling' and dispel the myth that international students cannot excel in Western workplaces.",
      ourValueTitle: "Our Values",
      ourValueText: "We are dedicated to helping every student realize their potential and advance further along their career path.",
      achievementsTitleThing: "Some Of Our Achievements ",
    },
    zh: {
      achievements: "Offer成果",
      plans: "服务指南",
      contact: "联系我们",
      faq: "常见问题",
      mission: "我们的使命",
      missionText: "我们致力于帮助北美留学生在金融、商科、CS、市场营销、咨询等领域中获得心仪的实习机会",
      services: "我们的服务",
      servicesText: "我们坚信只有提升自己，才能在职业道路上越走越远。我们全面的职业课程将为您打造突出的职场竞争力。",
      advantages: "我们的优势",
      advantagesText: "为Z世代量身定制的辅导平台,Intern-One致力于为每一个学生提供高效可靠、具有性价比的求职辅导服务。让来自美国各大顶尖名校及公司的资深Tutors直接和学员搭建起沟通的桥梁。",
      charge: "我们的收费模式",
      chargeText: "我们希望更多学生都能了解美国招聘的机制，具备求职的能力。我们以具有竞争力的价格为更多的同学取得进入心仪职场的大门的钥匙。",
      scheduleMeeting: "线上咨询",
      contactForm: "联系我们",
      nameLabel: "您的姓名:*",
      emailLabel: "邮箱地址:*",
      phoneNumberLabel: "微信 / 手机号:",
      countryLabel: "国家:*",
      messageLabel: "备注:*",
      sendButton: "提交",
      aboutUsText: "关于我们",
      aboutUs: "由Z世代为Z世代打造. Intern-One为北美留学生提供专业、细致、全面及个性化的求职指导服务. 我们的宗旨是帮助每一个有梦想的留学生找到他们的第一份实习工作. 我们坚信，只有通过不懈的努力,才能获得成功的果实。通过提升学生的职业素质、社交能力和面试技巧,帮助他们获得理想企业的青睐。我们的导师团队来自美国顶尖的TOP30名校, 就职于知名企业，他们将满怀热情地为每位学生提供服务。",
      ourGoalTitle: "我们的目标",
      ourGoalText: "一位学员，一个团队。通过我们专业，全面的个性化辅导，帮助我们的每位学员找到心仪的工作机会，扬帆起航。",
      ourVisionTitle: "我们的愿景",
      ourVisionText: "打破“Bamboo Ceiling”,帮助国际学生进入美国职场，大放异彩。",
      ourValueTitle: "我们的价值观 ",
      ourValueText: "一份耕耘，一份收获。我们致力去帮助每位学员发掘自己的潜能，助他们在职业道路上越走越远。",
      achievementsTitleThing: "部分Offer展示",
    }
  };
