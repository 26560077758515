import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Ensure this points to your updated CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section footer-logo">
          <Link to="/">
            Intern<span>One</span>
          </Link>
          <p>InternOne © {new Date().getFullYear()}. All rights reserved.</p>
          <div>
          </div>
        </div>
        <div className="footer-section footer-qr">
          <p>Contact us for a free one-to-one career consultation!</p>
          <img src={require('../../assets/img/wechat.jpg')} alt="QR Code for Services" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
