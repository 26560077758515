import React from 'react';
import { useLanguage } from '../../Context';
import './PlansPage.css'; // Make sure this path is correct

function PlansPage() {
  const { language } = useLanguage();
  const [loading, setLoading] = React.useState(true);

  const imageSrc = language === 'en' 
    ? require('../../assets/img/Intern-OneEnglish.png') 
    : require('../../assets/img/Intern-OneChinese.png');

  return (
    <div className="plans-page">
      <div className="banner-image"></div>
      <div className="plans-container">
        <h1 className="plans-header">{language === 'en' ? 'Our Plans' : '我们的计划'}</h1>
        {loading && <div className="loading-state">Loading...</div>}
        <img
          src={imageSrc}
          alt={language === 'en' ? 'Our Plans' : '我们的计划'}
          className="plans-image"
          onLoad={() => {
            setLoading(false);
            console.log('Image loaded successfully');
          }}
          onError={() => {
            setLoading(false);
            console.error('Failed to load image');
          }}
        />
      </div>
    </div>
  );
}

export default PlansPage;
