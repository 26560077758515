import React from 'react';
import './HomePage.css'; // Make sure to link the CSS file correctly
import { useLanguage } from '../../Context';
import { translations } from '../../Translation';
import { FaBriefcase, FaGraduationCap, FaNetworkWired } from 'react-icons/fa';


function HomePage() {
  const { language } = useLanguage();
  const text = translations[language];

  return (
    <div className="homepage">
      <div className="hero-section">
        <h1>Welcome to InternOne</h1>
        <p>Empowering your career journey with expert guidance</p>
      </div>

      <div className="about-content">
        <h2>{text.aboutUsText}</h2>
        <p>{text.aboutUs}</p>
        <div className="about-highlights">
          <div className="highlight">
            <FaBriefcase size={50} color="#0078D7" />
            <p>Customized job search guidance</p>
          </div>
          <div className="highlight">
            <FaGraduationCap size={50} color="#0078D7" />
            <p>Professional skill development</p>
          </div>
          <div className="highlight">
            <FaNetworkWired size={50} color="#0078D7" />
            <p>Networking opportunities</p>
          </div>
        </div>
      </div>

      <div className="services-section">
        <div className="service">
          <h3>{text.ourGoalTitle}</h3>
          <p>{text.ourGoalText}</p>
          {/* <button className="service-button">Learn More</button> */}
        </div>
        <div className="service">
          <h3>{text.ourVisionTitle}</h3>
          <p>{text.ourVisionText}</p>
          {/* <button className="service-button">Learn More</button> */}
        </div>
        <div className="service">
          <h3>{text.ourValueTitle}</h3>
          <p>{text.ourValueText}</p>
          {/* <button className="service-button">Learn More</button> */}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
