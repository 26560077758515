import React, { useState, useEffect, useRef, useCallback } from 'react';
import './AchievementPage.css';
import { translations } from '../../Translation';
import { useLanguage } from '../../Context';
import { FaTimes } from 'react-icons/fa';

const achievementsData = [
    {
        id: 1,
        companyName: "PWC",
        offerImage: require('../../assets/img/IMG_6202.JPEG'),
        storyImage: require('../../assets/img/Screenshot1.png')
    },
    {
        id: 2,
        companyName: "EY",
        offerImage: require('../../assets/img/IMG_6203.PNG'),
        storyImage: require('../../assets/img/Screenshot3.png')
    },
    {
        id: 3,
        companyName: "NYSCF",
        offerImage: require('../../assets/img/IMG_6201.JPEG'),
        storyImage: require('../../assets/img/Screenshot2.png')
    },
    {
        id: 4,
        companyName: "Houlihan Lokey",
        offerImage: require('../../assets/img/IMG_6381.jpeg'),
        storyImage: require('../../assets/img/Screenshot4.png')
    },
    {
        id: 5,
        companyName: "Seabury",
        offerImage: require('../../assets/img/Seabury1.png'),
        storyImage: require('../../assets/img/Seabury2.png')
    }
];

function AchievementsPage() {
    const [activeAchievement, setActiveAchievement] = useState(null);
    const { language } = useLanguage();
    const text = translations[language];
    const modalRef = useRef();

    const handleAchievementClick = (achievement) => {
        setActiveAchievement(achievement);
    };

    const closeDetails = () => {
        setActiveAchievement(null);
    };

    const handleClickOutside = useCallback((event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeDetails();
        }
    }, []);

    useEffect(() => {
        if (activeAchievement) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activeAchievement, handleClickOutside]);

    return (
        <div className="achievements-page">
            <div className="banner-image"></div>
            <div className="achievement-title">
                {text.achievementsTitleThing}
            </div>
            <div className="achievements-container">
                {achievementsData.map(achievement => (
                    <div key={achievement.id} className="achievement-item" onClick={() => handleAchievementClick(achievement)}>
                        <img src={achievement.offerImage} alt={`Offer Letter from ${achievement.companyName}`} />
                        <div className="company-name-overlay">
                            <span className="company-name">{achievement.companyName}</span>
                        </div>
                    </div>
                ))}
            </div>

            {activeAchievement && (
                <div className="achievement-detail-modal-container">
                    <div className="achievement-detail-modal" ref={modalRef}>
                        <button onClick={closeDetails} className="close-button"><FaTimes /></button>
                        <h2>{activeAchievement.companyName}</h2>
                        <img src={activeAchievement.offerImage} alt={`Detailed Offer Letter from ${activeAchievement.companyName}`} className="modal-image" />
                        <img src={activeAchievement.storyImage} alt={`Story from ${activeAchievement.companyName}`} className="modal-image" />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AchievementsPage;
