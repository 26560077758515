import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Navbar.css';  // Ensure this points to your updated CSS file
import { useLanguage } from '../../Context';
import { translations } from '../../Translation';

function CustomNavbar() {
    const { language, toggleLanguage } = useLanguage();
    const [isScrolled, setIsScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const navbarRef = useRef(null);
    const text = translations[language];

    useEffect(() => {
        const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navbarRef]);

    return (
        <Navbar
            expand="lg"
            fixed="top"
            className={isScrolled ? 'scrolled' : ''}
            expanded={expanded}
            ref={navbarRef}
        >
            <Container fluid>
                <Navbar.Brand as={Link} to="/">Intern<span>One</span></Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link as={Link} to="/achievements">{text.achievements}</Nav.Link>
                        <Nav.Link as={Link} to="/plans">{text.plans}</Nav.Link>
                        <Nav.Link as={Link} to="/contact">{text.contact}</Nav.Link>
                        <Nav.Link as={Link} to="/faq">{text.faq}</Nav.Link>
                    </Nav>

                    <Nav className="language-toggle">
                        <Nav.Link onClick={() => toggleLanguage('en')}>English</Nav.Link>
                        <span>|</span>
                        <Nav.Link onClick={() => toggleLanguage('zh')}>中文</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
